import React from 'react';
import ReactDOM from 'react-dom';
import {isExpired} from 'react-jwt';
import ReactGA from 'react-ga4';

import './index.css';
import App from './App';
import FirstAccess from './FirstAccess';
// import reportWebVitals from './reportWebVitals';

const APP_VERSION = "v0.0.5";
const GA_TRACKING_ID = "G-K96R3ZTWDC";

if (process.env.NODE_ENV === 'production')
  ReactGA.initialize(GA_TRACKING_ID);

var elem = null;
if (!hasToken() || !validToken()){
  window.location.hash = "#primeiro_acesso";
  elem = <React.StrictMode><FirstAccess version={APP_VERSION}/></React.StrictMode>;
} else{
  window.location.hash = "#inicio";
  elem = <React.StrictMode><App version={APP_VERSION}/></React.StrictMode>;
}
ReactDOM.render(
  elem, document.getElementById('root')
);

function hasToken(){
  return window.localStorage.getItem('sessionToken') !== null;
}

function validToken(){
  return !isExpired(window.localStorage.getItem('sessionToken'));
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
