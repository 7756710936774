import { BsDropletFill, BsDropletHalf, BsDroplet } from "react-icons/bs";

import { humidityColor, textColor } from "./";

interface HumidityProps {
    name: string;
    current_value: number;
    size?: string | number;
    unit?: string;
}

export function Humidity(props: HumidityProps) {
    const color = humidityColor;
    const size = props.size || "5em";
    const unit = props.unit || "%";
    const iconProps = {color: color, size: size};

    const chosenDroplet = (humidityValue: number) => {
        if (humidityValue < 33)
            return <BsDroplet {...iconProps}/>;
        else if (humidityValue < 66)
            return <BsDropletHalf {...iconProps}/>;
        else
            return <BsDropletFill {...iconProps}/>;
    };

    return(
        <div>
            <div className="text-truncate" style={{color: textColor}}>
                <span>{props.name}</span>
            </div>
            <div style={{width: size, height: size, position: "relative", left: "50%", transform: "translate(-50%, 0)"}}>
                <div style={{position: "absolute", top: 0, left: 0}}>
                    {chosenDroplet(props.current_value)}
                </div>

                <div className="text-truncate"
                    style={{color: textColor, fontWeight: "bold", position: "absolute", top: "50%", left: "50%",
                            transform: "translate(-50%,0)"}}
                >{props.current_value}{unit}</div>

            </div>
        </div>
    );
}
