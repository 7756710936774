const vapid_public_key: string = "BF60XQAHXG7U-vDum4UaiALrXIwY5emfTzmXaY8WEsmSrmWf32JCtQ5q8I1fSHvyNT4bWQIALrSf3wzhhXcDB30";

// function urlB64ToUint8Array(base64String: string): Uint8Array {
//   const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
//   const base64 = (base64String + padding)
//     .replace(/\-/g, "+")
//     .replace(/_/g, "/");

//   const rawData = window.atob(base64);
//   const outputArray = new Uint8Array(rawData.length);

//   for (let i = 0; i < rawData.length; ++i) {
//     outputArray[i] = rawData.charCodeAt(i);
//   }
//   return outputArray;
// }

export interface PushSubscriptionStatus {
  readonly currentSubscription: PushSubscription,
  readonly changed: boolean,
  readonly oldSubscription?: PushSubscription,
};

export async function subscribe_to_push(swReg: ServiceWorkerRegistration): Promise<PushSubscriptionStatus> {
  // const registration = await navigator.serviceWorker.ready;
  // console.log("subscribe_to_push registration:", registration);

  const pushSubscription = await swReg.pushManager.getSubscription();
  // console.log("subscribe_to_push pushSubscription:", pushSubscription);

  if(!pushSubscription){
    //the user was never subscribed
    const newSubscription = await swReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: vapid_public_key,
    });
    console.log("subscribe_to_push newSubscription:", newSubscription);
    return {
      currentSubscription: newSubscription,
      changed: true,
    };

  } else {
    // const json = pushSubscription.toJSON();
    // const public_key = json.keys?.p256dh;

    // console.log("public_key", public_key);
    // console.log("vapid_public_key", vapid_public_key);
    // if(public_key != vapid_public_key){
    //   console.log("Should unsubscribe and subscribe again with new key");

    //   const res = await pushSubscription.unsubscribe();
    //   console.log("unsubscribe res:", res);
    //   const newSubscription = await swReg.pushManager.subscribe({
    //     userVisibleOnly: true,
    //     applicationServerKey: vapid_public_key,
    //   });

    //   console.log("newSubscription key:", newSubscription.toJSON().keys);

    //   var statusObj: PushSubscriptionStatus = {
    //     currentSubscription: newSubscription,
    //     changed: true,
    //     oldSubscription: pushSubscription,
    //   };


    // } else {
    //   console.log("public keys are equal, proceeding...");

    //   var statusObj: PushSubscriptionStatus = {
    //     currentSubscription: pushSubscription,
    //     changed: false,
    //   };
    // }
    var statusObj: PushSubscriptionStatus = {
      currentSubscription: pushSubscription,
      changed: false,
    };

    return statusObj;
  }
}
