import { tempColor, textColor, fillingColor, tempCoutourColor, tempContourWidth } from "./";

interface TemperatureProps {
    name: string;
    value: number;
    maxVal?: number;
    minVal?: number;
    size?: string;
    redLine?: number;
    blueLine?: number;
  };

export function Temperature(props: TemperatureProps) {
    const maxTempY = 21.75;
    const minTempY = 165;

    const maxTempVal = props.maxVal || 35;
    const minTempVal = props.minVal || 15;
    const sizeVal = props.size || "30%";

    const val = Math.max(Math.min(props.value, maxTempVal), minTempVal);

    const a = (maxTempY - minTempY) / (maxTempVal - minTempVal);
    const b = minTempY - a * minTempVal;
    const yPadding = a * val + b;

    return(
      <div>
        <div style={{color: textColor}}>
          <div><span>{props.name}</span></div>
        </div>
        <div>
          <svg height={sizeVal} width={sizeVal} viewBox="0 0 120 200">
            <circle className="circleTop" r="17.75" cx="60" cy={maxTempY} style={{fill: fillingColor, stroke: tempCoutourColor, strokeWidth: tempContourWidth}}></circle>
            <rect className="rect" x="42.25" y={maxTempY} height="147.25" width="35.5" style={{fill: fillingColor, stroke: tempCoutourColor, strokeWidth: tempContourWidth}}></rect>
            <circle className="circleTop2" r="14.75" cx="60" cy={maxTempY} style={{fill: fillingColor, stroke: "none", strokeWidth: tempContourWidth}}></circle>
            <circle className="circleBottom" r="30" cx="60" cy={minTempY} style={{fill: fillingColor, stroke: tempCoutourColor, strokeWidth: tempContourWidth}}></circle>
            <rect className="rect2" x="46" y={maxTempY} height={minTempY - maxTempY} width="28" style={{fill: fillingColor, stroke: fillingColor, strokeWidth: "1mm"}}></rect>
            <circle r="20" cx="60" cy={minTempY} data-type="base-circle-fill" style={{fill: tempColor, stroke: tempColor, strokeWidth: "4px"}}></circle>
            <rect x="50.25" y={yPadding} width="19.5" height={minTempY - yPadding} data-type="fill" style={{fill: tempColor}}></rect>
            <circle r="9.7" cx="60" cy={yPadding} data-type="circle-fill" style={{fill: tempColor}}></circle>
            <line className="line" x1="80" x2="90" y1="123.75" y2="123.75" style={{stroke: tempCoutourColor, strokeWidth: tempContourWidth}}></line>
            <line className="line" x1="80" x2="90" y1="97.5" y2="97.5" style={{stroke: tempCoutourColor, strokeWidth: tempContourWidth}}></line>
            <line className="line" x1="80" x2="90" y1="71.25" y2="71.25" style={{stroke: tempCoutourColor, strokeWidth: tempContourWidth}}></line>
            <line className="line" x1="80" x2="90" y1="45" y2="45" style={{stroke: tempCoutourColor, strokeWidth: tempContourWidth}}></line>

            {props.redLine? <line className="line" x1="0" x2="150" y1={(a * props.redLine! + b) - 7} y2={(a * props.redLine! + b) - 7} style={{stroke: "red", strokeWidth: tempContourWidth}}/> : <></>}
            {props.blueLine? <line className="line" x1="0" x2="150" y1={(a * props.blueLine! + b) - 7} y2={(a * props.blueLine! + b) - 7} style={{stroke: "blue", strokeWidth: tempContourWidth}}/> : <></>}
          </svg>
        </div>

        <div>
          <div className="text-truncate" style={{color: textColor, fontWeight: "bold"}}>{props.value}ºC</div>
          {props.blueLine? <div className="text-truncate" style={{color: "blue", fontSize: "12px", fontWeight: "bold"}}>Resfriamento : {props.blueLine}ºC</div> : <></>}
          {props.redLine? <div className="text-truncate" style={{color: "red", fontSize: "12px", fontWeight: "bold"}} >Aquecimento : {props.redLine}ºC</div> : <></>}
          <p/>
        </div>
      </div>
    );
  }
