import React from 'react';

import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl'
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const first_access_server = "https://us-east1-remote-farm-proj.cloudfunctions.net/primeiro_acesso";

const paramKeyName = "chave";

class FirstAccess extends React.Component{
  constructor(props){
    super(props);

    let currentUrl = new URL(window.location.href);
    if (currentUrl.searchParams.has(paramKeyName)){
      this.state = {
        typedKey: currentUrl.searchParams.get(paramKeyName),
        loading: true
      }
    } else{
      this.state = {
        typedKey: "",
        loading: false
      }
    }
    this.state.showError = false;
    this.state.errorMsg = "";
  }

  componentDidMount(){
    // if there is a key in the URL, send it
    let currentUrl = new URL(window.location.href);
    if (currentUrl.searchParams.has(paramKeyName)){
      window.history.replaceState(null, window.document.title, window.location.origin + window.location.hash);
      this.sendData(this.state.typedKey);
    }
  }

  sendData(key){
    if (typeof key !== 'string' || key.length <= 0){
      this.setState({
        loading: false,
        showError: true,
        errorMsg: "Digite uma chave de acesso válida"
      });
      return;
    }

    fetch(first_access_server, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({chave: key}),
    }).then(res => {
      if (!res.ok){
        var requestErrorMsg = "Erro de conexão! Tente novamente mais tarde.";

        if (res.status === 401)
          requestErrorMsg = "Chave Inválida! Entre em contato com o responsável pelo aplicativo!";

        this.setState({
          loading: false,
          showError: true,
          errorMsg: requestErrorMsg
        });
        return Promise.reject(res.status);
      }
      return res.json();
    }).then(data => {
      this.setState({
        loading: false,
        showError: false
      });
      window.localStorage.setItem('sessionToken', data.token);
      window.location.reload();

    }).catch(err => {
      if (err !== 401){
         var requestErrorMsg = "Erro de conexão! Verifique se está conectado na internet.";
        this.setState({
          loading: false,
          showError: true,
          errorMsg: requestErrorMsg
        });
      }
    });

    this.setState({
      loading: true,
      showError: false
    });
  }

  render() {
    return(
      <>
        <div className="App">
          <header className="App-header">
            <div className="mb-2">Digite sua Chave de Acesso</div>
            {this.state.loading && <Spinner className="mb-2" animation="border" variant="info"/>}

            <InputGroup className="mb-3">
              <FormControl
                id="typedKey"
                placeholder="Chave"
                aria-label="Chave"
                type="text"
                value={this.state.typedKey}
                disabled={this.state.loading}
                onChange={(val) => {this.setState({typedKey: val.target.value})}}
              />
              <InputGroup>
                <Button
                  variant="primary"
                  disabled={this.state.loading}
                  onClick={() => {this.sendData(this.state.typedKey)}}
                >{this.state.loading? "Enviando..." : "Enviar"}</Button>
              </InputGroup>
            </InputGroup>

            <Alert
              variant="danger"
              show={this.state.showError}
            >{this.state.errorMsg}</Alert>

            <div className="App-version">
              {this.props.version}
            </div>
          </header>
        </div>
      </>
    );
  }
}

export default FirstAccess;
