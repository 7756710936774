export { Temperature } from "./Temperature";
export { Humidity } from "./Humidity";
export { Gauge } from "./Gauge";


export const tempColor = "#EFBA7E";
export const gaugeColor = "#8B99F7";
export const textColor = "black";
export const fillingColor = "white";
export const humidityColor = "#9691e0";

export const tempCoutourColor = "black";
export const tempContourWidth = "1mm";
